<template>
  <div class="relative">
    <div
      ref="slider"
      class="keen-slider min-h-80 lg:min-h-100 lg:max-h-130 bg-black"
    >
      <!-- grid place-items-center should be used in case in the future we have to support both variable heights and variable widths, 
      in that case, removal of h-full and w-full is also necessary from the img tag-->
      <div
        v-for="(image, index) in images"
        :key="index"
        class="keen-slider__slide grid place-items-center"
      >
        <img
          :src="image.url"
          :class="[
            'h-auto max-h-100 lg:max-h-130 object-scale-down',
            { 'opacity-50': current !== index }
          ]"
          :alt="$t('property_img')"
        />
      </div>
    </div>
    <button
      :disabled="images.length === 1"
      :class="[
        'arrow-btn p-5 lg:pl-22 absolute z-20 top-1/2 left-0 text-white text-lg lg:text-3xl disabled:bg-transparent'
      ]"
      @click="slider.prev()"
    >
      <i class="fal fa-chevron-left" />
    </button>
    <button
      :disabled="images.length === 1"
      :class="[
        'arrow-btn p-5 lg:pr-22 absolute z-20 top-1/2 right-0 text-white text-lg lg:text-3xl disabled:bg-transparent'
      ]"
      @click="slider.next()"
    >
      <i class="fal fa-chevron-right" />
    </button>
    <!-- <div
      v-if="slider"
      class="py-10 w-full absolute bottom-0 z-20 gradient-header-fade-bottom"
    >
      <div class="flex justify-center items-center transform rotate-180">
        <button
          v-for="(image, index) in images"
          :key="index"
          class="mx-1.5 h-1.5 w-1.5 lg:mx-2.5 lg:h-2.5 lg:w-2.5 shadow-card rounded-full hover:bg-brand-primary"
          :class="current === index ? 'bg-brand-primary' : 'bg-gray-e9'"
          @click="slider.moveToSlideRelative(index)"
        />
        <span class="pl-2.5 text-sm lg:text-base font-semibold text-gray-e9">
          +{{ images.length }}
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import NoImageAvailable from '@/assets/no_image_available.gif';

export default {
  name: 'PropertyImageCarousel',
  props: {
    carouselImages: {
      type: Array
    }
  },
  data() {
    return {
      current: 0,
      slider: null
    };
  },
  computed: {
    images() {
      if (this.carouselImages?.length) return this.carouselImages;
      else return [{ url: NoImageAvailable }];
    }
  },
  mounted() {
    this.slider = new KeenSlider(this.$refs.slider, {
      initial: this.current,
      centered: true,
      slidesPerView: 1,
      loop: this.images.length > 1 ? true : false,
      slideChanged: slide => {
        this.current = slide.details().relativeSlide;
      },
      breakpoints: {
        '(min-width: 1200px)': {
          slidesPerView: this.images.length > 2 ? 3 : this.images.length
        }
      }
    });
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  }
};
</script>

<style scoped>
.arrow-btn:focus {
  outline: none;
}
.arrow-btn::-moz-focus-inner {
  border: 0;
}
</style>
