var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{ref:"slider",staticClass:"keen-slider min-h-80 lg:min-h-100 lg:max-h-130 bg-black"},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"keen-slider__slide grid place-items-center"},[_c('img',{class:[
          'h-auto max-h-100 lg:max-h-130 object-scale-down',
          { 'opacity-50': _vm.current !== index }
        ],attrs:{"src":image.url,"alt":_vm.$t('property_img')}})])}),0),_c('button',{class:[
      'arrow-btn p-5 lg:pl-22 absolute z-20 top-1/2 left-0 text-white text-lg lg:text-3xl disabled:bg-transparent'
    ],attrs:{"disabled":_vm.images.length === 1},on:{"click":function($event){return _vm.slider.prev()}}},[_c('i',{staticClass:"fal fa-chevron-left"})]),_c('button',{class:[
      'arrow-btn p-5 lg:pr-22 absolute z-20 top-1/2 right-0 text-white text-lg lg:text-3xl disabled:bg-transparent'
    ],attrs:{"disabled":_vm.images.length === 1},on:{"click":function($event){return _vm.slider.next()}}},[_c('i',{staticClass:"fal fa-chevron-right"})])])}
var staticRenderFns = []

export { render, staticRenderFns }